@import "./variables.scss";

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  .contact-header-wrapper {
    display: flex;
    justify-content: center;
    h2 {
      display: flex;
      align-items: center;
      font-family: $font-body;
      font-size: 50px;
      color: $text-primary;

      span {
        color: $secondary;
      }
      @media (max-width: $tb) {
        font-size: 40px;
      }
    }
  }
  .form-container {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    @media (max-width: $tb) {
      margin-top: 10px;
    }
    form {
      width: 40%;
      min-width: 350px;
      display: flex;
      flex-direction: column;
      input {
        margin-bottom: 10px;
        background-color: $third;
        border: 0px;
        outline: none;
        font-size: 18px;
        color: $text-secondary;
        padding: 5px 10px;
        border-radius: 10px;
        &:focus {
          transition: 0.5s;
          border-radius: 10px;
          box-shadow: 0 0 2pt 1pt $secondary;
        }
      }
      textarea {
        margin-bottom: 20px;
        background-color: $third;
        border: 0px;
        outline: none;
        font-size: 18px;
        color: $text-secondary;
        padding: 5px 10px;
        border-radius: 10px;
        &:focus {
          transition: 0.5s;
          border-radius: 10px;
          box-shadow: 0 0 2pt 1pt $secondary;
        }
      }
      label {
        font-family: $digital;
        color: $secondary;
        margin-bottom: 5px;
      }
      button {
        margin-bottom: 5px;
        background-color: transparent;
        border: $secondary 1px solid;
        border-radius: 5px;
        padding: 9px 15px;
        color: $secondary;
        transition: 0.5;
        cursor: pointer;
        &:hover {
          transition: 0.5;
          background-color: rgba($secondary, 0.3);
        }
      }
      .form-loading {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .form-sended {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $secondary;
        font-family: $digital;
        font-size: 20px;
      }
      .error-messages {
        font-family: $font-body;
        margin-top: -10px;
        margin-bottom: 10px;
        font-size: 14px;
        color: red;
      }
    }
  }
}
