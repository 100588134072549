@import "./variables.scss";

#work-section {
  .work-container {
    .work-mainHeader {
      display: flex;
      align-items: center;
      font-family: $font-body;
      font-size: 38px;
      font-weight: 600;
      color: $text-primary;
      &.otherWorks {
        margin-top: 50px;
        @media (max-width: $tb) {
          margin-top: 75px;
        }
      }
      @media (max-width: $md) {
        margin-bottom: 25px;
      }
      @media (max-width: $tb) {
        margin-bottom: 20px;
        font-size: 30px;
      }
      @media (max-width: $sm) {
        margin-bottom: 30px;
        font-size: 30px;
      }
      i {
        font-size: 22px;
        margin-right: 15px;
        margin-bottom: 4px;
        color: $secondary;
        @media (max-width: $sm) {
          font-size: 14px;
        }
      }
    }
    .work-wrappers {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin: 0 -25px;

      @media (max-width: $sm) {
        margin: 0;
      }

      .workSvg-container {
        padding: 0 15px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        @media (max-width: $tb) {
          display: none;
        }
        @media (max-width: $sm) {
          display: none;
        }
        .workSvg-wrapper {
          max-width: 550px;
          min-width: 550px;
          align-items: center;
          @media (max-width: $md) {
            max-width: 350px;
            min-width: 350px;
          }
          svg > path {
            stroke: $secondary;
            stroke-width: 2px;
          }
        }
      }
      .workText-wrapper {
        padding: 0 25px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 100px;
        @media (max-width: $tb) {
          padding-bottom: 50px;
        }
        &.even {
          padding-bottom: 0px;
          @media (max-width: $tb) {
            padding-bottom: 50px;
          }
        }
        @media (max-width: $sm) {
          padding: 0px;
        }
        .workHeader-wrapper {
          @media (max-width: $sm) {
            margin-bottom: 40px;
          }
          span {
            font-family: $digital;
            color: $secondary;
            font-size: 18px;
            @media (max-width: $md) {
              font-size: 16px;
            }
            @media (max-width: $tb) {
              font-size: 15px;
            }
            @media (max-width: $sm) {
              font-size: 14px;
            }
          }
          h3 {
            font-family: $font-body;
            color: $text-primary;
            font-size: 32px;
            @media (max-width: $md) {
              font-size: 30px;
            }
            @media (max-width: $tb) {
              font-size: 26px;
            }
            @media (max-width: $sm) {
              font-size: 26px;
            }
          }
          p {
            margin-top: 20px;
            font-family: $font-body;
            color: $text-secondary;
            font-size: 17px;
            line-height: 1.6;
            @media (max-width: $md) {
              font-size: 16px;
            }
            @media (max-width: $tb) {
              font-size: 15px;
            }
            @media (max-width: $sm) {
              font-size: 15px;
            }
          }
          .mobile-img {
            display: none;
            @media (max-width: $tb) {
              width: 100%;
              display: block;
              position: absolute;
              left: 0;
              z-index: -1;
              opacity: 0.2;
            }
            @media (max-width: $sm) {
              width: 100%;
              display: block;
              position: absolute;
              left: 0;
              z-index: -1;
              opacity: 0.2;
            }
          }
          .whatTech-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: left;
            @media (max-width: $sm) {
              justify-content: space-between;
            }
            ul {
              list-style: none;
              padding: 0;
              margin-right: 30px;
              @media (max-width: $sm) {
                margin-right: 0px;
              }
              li {
                display: flex;
                align-items: center;
                font-size: 17px;
                font-family: $digital;
                color: $text-primary;
                @media (max-width: $sm) {
                  font-size: 15px;
                }
                i {
                  font-size: 10px;
                  color: $secondary;
                  margin-right: 6px;
                  @media (max-width: $sm) {
                    font-size: 8px;
                  }
                }
              }
            }
          }
          .work-links {
            display: flex;
            justify-content: flex-end;
            @media (max-width: $sm) {
              justify-content: center;
            }
            i {
              font-size: 22px;
              color: $text-primary;
              margin-right: 20px;
              transition: 0.5s;
              @media (max-width: $sm) {
                font-size: 20px;
              }
              &:hover {
                color: $secondary;
                transition: 0.5s;
              }
            }
          }
        }
      }
    }
  }
  .other-works-container {
    margin-top: 50px;
    @media (max-width: $sm) {
      margin-top: 25px;
    }
    .col-xl-4 {
      @media (max-width: $xl) {
        margin-bottom: 25px;
      }
      @media (max-width: $md) {
        margin-bottom: 25px;
      }
      @media (max-width: $sm) {
        margin-bottom: 25px;
      }
    }
    .other-works-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: $third;
      height: 100%;
      transition: 0.2s;
      &:hover {
        transform: translate(-5px, -5px);
        transition: 0.2s;
      }
      filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.4));
      padding: 20px;
      .actions-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        :nth-child(1) {
          color: $secondary;
          font-size: 50px;
        }
        :nth-child(2) {
          i {
            color: $text-primary;
            font-size: 35px;
          }
        }
      }
      .detail-wrapper {
        margin-top: 20px;
        font-family: $font-body;
        h3 {
          color: $text-primary;
        }
        p {
          margin-top: 20px;
          color: $text-secondary;
        }
      }
      .whatUse-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        list-style: none;
        font-family: $digital;
        color: $text-secondary;
        padding: 0;
        margin-top: 20px;
      }
    }
  }
}
