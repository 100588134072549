//colors
$primary: #0a192f;
$secondary: #64ffda;
$third: #112240;
$text-primary: #ccd6f6;
$text-secondary: #8892b0;

//breakpoints
$xl: 1440px;
$md: 1231px;
$tb: 900px;
$sm: 576px;

///fonts
$digital: "Inconsolata", monospace;
$font-body: "Cabin", sans-serif;
