@import "./variables.scss";

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#ipl-progress-indicator {
  transition: 1s;
  &.fadeOut {
    opacity: 0;
    transition: 1s;
  }
  .insp-logo-frame {
    svg {
      position: absolute;
      top: 45%;
      left: 45%;
      @media (max-width: $sm) {
        top: 40%;
        left: 35%;
      }
    }
  }
}

.triangleSVG {
  transform-origin: 41% 60%;
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

#content {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  padding: 0px 150px;
  margin: 0px auto;
  background-color: $primary;
  @media (max-width: $tb) {
    padding: 0px 50px;
  }
  @media (max-width: $sm) {
    padding: 0px 25px;
  }
  .sections {
    height: 100vh;
    max-width: 1600px;
    margin: 0px auto;
    @media (max-width: $tb) {
      height: auto;
    }
  }
  #top-section {
    @media (max-width: $tb) {
      height: 100vh;
    }
  }
  #work-section {
    margin: 0px auto;
    height: auto;
    @media (max-width: $xl) {
      margin: 0px auto;
    }
    @media (max-width: $md) {
      margin: 0px auto;
    }

    @media (max-width: $sm) {
      margin: 0px auto;
      padding-top: 50px;
    }
  }
  #about-section {
    @media (max-width: $sm) {
      height: auto;
    }
  }
}
