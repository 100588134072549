@import "./variables.scss";

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.nav-container {
  z-index: 50;
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgba($primary, $alpha: 0.85);
  backdrop-filter: blur(8px);
  font-size: 18px;
  font-family: $digital;

  &.on-top {
    filter: none;
    transition: 0.5s;
  }
  &.not-top {
    transition: 0.5s;
    filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.6));
  }

  .logo-wrapper {
    position: relative;
    width: 75px;
    height: 75px;
    margin: 4px 10px 6px 10px;
    display: flex;
    justify-content: center;

    .letter-svg {
      position: absolute;
      z-index: 1000;
      top: 9px;
      left: 10px;
      fill: $secondary;
    }

    .shapes-svg {
      position: absolute;
      top: -2px;
      left: 6px;
      z-index: 1;
      stroke: $secondary;
      stroke-width: 2px;
      transform-origin: 50% 70%;
      -webkit-animation: rotating 3s linear infinite;
      -moz-animation: rotating 3s linear infinite;
      -ms-animation: rotating 3s linear infinite;
      -o-animation: rotating 3s linear infinite;
      animation: rotating 3s linear infinite;

      &.square {
        top: 9px;
        left: 5px;
        transform-origin: 50% 50%;
      }

      &.poly {
        top: 16px;
        left: 8px;
        transform-origin: 45% 40.5%;
        .shape-path {
          transform: scale(1);
        }
      }

      &.circle {
        top: 10px;
        left: 5px;
        transform-origin: 50% 50%;
      }

      .shape-path {
        fill: $secondary;
        stroke-width: 0px;
      }
    }
  }

  .nav-menu {
    width: 500px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .nav-items {
      @media (max-width: $sm) {
        display: none;
      }
      width: 100%;
      display: flex;
      justify-content: space-around;
      list-style: none;
      margin: 0;
      li {
        display: flex;
        align-items: center;
        padding: 10;
        .section-svg {
          display: block;
          width: 15px;
          opacity: 0;
        }
        &:hover {
          .section-svg {
            transition: 1.5s;
            opacity: 1;
            transform-origin: 50% 50%;
            -webkit-animation: rotating 4s linear infinite;
            -moz-animation: rotating 4s linear infinite;
            -ms-animation: rotating 4s linear infinite;
            -o-animation: rotating 4s linear infinite;
            animation: rotating 4s linear infinite;
          }
        }
        a {
          &:hover {
            transition: 0.5s;
            color: $secondary;
          }
          transition: 0.5s;
          opacity: 1;
          text-decoration: none;
          color: white;
          margin-left: 5px;
        }
      }
      .resume-button {
        border: $secondary 1px solid;
        border-radius: 5px;
        padding: 9px 15px;
        color: $secondary;
        transition: 0.5;
        cursor: pointer;
        &:hover {
          transition: 0.5;
          background-color: rgba($secondary, 0.3);
        }
      }
    }
  }
  .burger-button {
    display: none;

    @media (max-width: $sm) {
      margin: 25px 25px 0px 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 35px;
      height: 35px;
      background: transparent;
      border: none;
      cursor: pointer;
      padding: 0;
      z-index: 10000;
      &:focus {
        outline: none;
      }
      div {
        width: 35px;
        height: 2px;
        border-radius: 2px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;
        background-color: $secondary;

        &:first-child {
          transform: rotate(0);
          &.open {
            transform: rotate(45deg);
          }
        }
        &:nth-child(2) {
          transform: translateX(0px);
          opacity: 1;
          &.open {
            transform: translateX(45deg);
            opacity: 0;
          }
        }
        &:nth-child(3) {
          transform: rotate(0);
          &.open {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
  .nav-sidebar {
    display: none;
    @media (max-width: $sm) {
      position: absolute;
      right: 0;
      width: calc(100% - 100px);
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 80px 5px;
      background-color: $third;
      transform: translateX(100%);
      transition: 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
      &.open {
        transition: 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
        transform: translateX(0);
        filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.4));
      }
      .mobile-nav-items {
        margin: 0;
        padding: 0;
        height: 80%;
        display: flex;
        list-style: none;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        li {
          a {
            &:hover {
              transition: 0.5s;
              color: $secondary;
            }
            transition: 0.5s;
            text-decoration: none;
            color: white;
          }
          .resume-button {
            border: $secondary 1px solid;
            border-radius: 5px;
            padding: 9px 30px 11px 30px;
            color: $secondary;
            transition: 0.5;
            cursor: pointer;
            &:hover {
              transition: 0.5;
              background-color: rgba($secondary, 0.3);
            }
          }
        }
      }
      .mobile-icons {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        a {
          margin: 0px 10px;
          i {
            font-size: 26px;
            color: white;
            &:hover {
              color: $secondary;
            }
          }
        }
      }
    }
  }
}
.blur-layer {
  display: none;
  &.open {
    display: block;
    position: fixed;
    z-index: 5;
    width: 100%;
    height: 100vh;
    background-color: rgba(white, $alpha: 0);
    backdrop-filter: blur(4px);
  }
}
