@import "./variables.scss";

.fixed-leftSide {
  width: 10px;
  position: fixed;
  left: 50px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  a {
    margin-bottom: 30px;
    i {
      color: $text-primary;
      font-size: 22px;
      transition: 0.5s;
      &:hover {
        color: $secondary;
        transition: 0.5s;
      }
    }
  }
  .line {
    height: 150px;
    width: 2px;
    background-color: $text-primary;
    margin-bottom: 0;
  }
  @media (max-width: $tb) {
    display: none;
  }
  @media (max-width: $sm) {
    display: none;
  }
}
.fixed-rightSide {
  position: fixed;
  right: 50px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 10px;
  span {
    font-family: $digital;
    color: $text-primary;
    transform: rotate(90deg);
    letter-spacing: 2px;
    transition: 0.5s;
    &:hover {
      color: $secondary;
      transition: 0.5s;
      cursor: pointer;
    }
  }

  .line {
    height: 150px;
    width: 2px;
    background-color: $text-primary;
    margin-bottom: 0;
    margin-top: 130px;
  }
  @media (max-width: $tb) {
    display: none;
  }
  @media (max-width: $sm) {
    display: none;
  }
}
