@import "./variables.scss";

#about-section {
  .about-container {
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    @media (max-width: $sm) {
      padding-top: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .aboutSvg-wrapper {
      min-width: 1000px;
      svg > path {
        stroke: $secondary;
        stroke-width: 2px;
        transform: scale(-50%, -50%);
      }
      @media (max-width: $xl) {
        min-width: 800px;
      }
      @media (max-width: $md) {
        min-width: 600px;
        margin-left: -20px;
      }
      @media (max-width: $tb) {
        min-width: 550px;
        margin-left: -20px;
      }
      @media (max-width: $sm) {
        padding: 0px 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .aboutText-wrapper {
      display: flex;
      flex-direction: column;
      margin-left: -300px;
      margin-top: 50px;
      width: 700px;

      @media (max-width: $xl) {
        margin-top: 0px;
      }
      @media (max-width: $md) {
        margin-left: -220px;
      }
      @media (max-width: $tb) {
        margin-left: -250px;
      }
      @media (max-width: $sm) {
        margin-left: 0px;
        margin-top: 0px;
        width: 100%;
      }
      .about-header {
        display: flex;
        align-items: center;
        font-family: $font-body;
        color: $text-primary;
        font-size: 36px;
        font-weight: 600;
        margin-bottom: 25px;

        @media (max-width: $xl) {
          font-size: 32px;
        }
        @media (max-width: $tb) {
          font-size: 26px;
        }
        @media (max-width: $sm) {
          font-size: 24px;
        }
        i {
          font-size: 20px;
          transform: rotate(45deg);
          margin-right: 20px;
          color: $secondary;
          @media (max-width: $tb) {
            font-size: 15px;
            margin-right: 14px;
          }
          @media (max-width: $sm) {
            font-size: 14px;
            margin-right: 15px;
          }
        }
      }
      .about-paragraph {
        font-family: $font-body;
        color: $text-secondary;
        font-size: 20px;
        font-weight: 400;
        @media (max-width: $xl) {
          font-size: 18px;
        }
        @media (max-width: $tb) {
          font-size: 14px;
        }
        @media (max-width: $sm) {
          font-size: 16px;
        }
      }
      .skills-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 15px;
        @media (max-width: $tb) {
          margin-top: 5px;
        }
        @media (max-width: $sm) {
          margin-top: 10px;
        }
        ul {
          list-style: none;
          padding: 0px;
          @media (max-width: $tb) {
            margin-left: 5px;
          }
          li {
            font-family: $digital;
            font-size: 18px;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            color: $text-secondary;
            @media (max-width: $xl) {
              font-size: 16px;
            }
            @media (max-width: $tb) {
              font-size: 12px;
            }
            @media (max-width: $sm) {
              font-size: 12px;
              margin-bottom: 5px;
            }
            a {
              text-decoration: none;
              color: $text-secondary;
            }
            i {
              font-size: 7px;
              color: $secondary;
              margin-right: 12px;
              padding-top: 1px;
              transform: rotate(45deg);
              @media (max-width: $tb) {
                font-size: 4px;
                margin-right: 5px;
              }
              @media (max-width: $sm) {
                font-size: 5px;
              }
            }
          }
        }
      }
    }
  }
}
