@import "./variables.scss";

#top-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .top-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: $tb) {
      padding-top: 50px;
    }
    .header-1 {
      h3 {
        font-family: $digital;
        color: $secondary;
        font-size: 18pt;
        font-weight: 300;
        @media (max-width: $md) {
          font-size: 14pt;
        }
        @media (max-width: $tb) {
          font-size: 13pt;
          margin: 0;
        }
        @media (max-width: $sm) {
          font-size: 12pt;
        }
      }
    }
    .header-2 {
      h1 {
        font-family: $digital;
        font-weight: 800;
        font-size: 70pt;
        color: $text-primary;

        @media (max-width: $xl) {
          font-size: 60pt;
        }
        @media (max-width: $md) {
          font-size: 48pt;
        }
        @media (max-width: $tb) {
          font-size: 36pt;
        }
        @media (max-width: $sm) {
          font-size: 34pt;
        }
      }
    }
    .header-3 {
      h2 {
        font-family: $font-body;
        font-weight: 800;
        font-size: 65pt;
        color: $text-secondary;
        @media (max-width: $xl) {
          font-size: 55pt;
        }
        @media (max-width: $md) {
          font-size: 40pt;
        }
        @media (max-width: $tb) {
          font-size: 30pt;
        }
        @media (max-width: $sm) {
          font-size: 28pt;
        }
      }
    }
    .main-paragraph {
      width: 600px;
      margin-top: 20px;
      @media (max-width: $tb) {
        margin-top: 0px;
      }
      p {
        font-family: $font-body;
        font-weight: 400;
        font-size: 14pt;
        color: $text-secondary;
        @media (max-width: $md) {
          font-size: 13pt;
          width: 450px;
        }
        @media (max-width: $tb) {
          font-size: 11pt;
          width: 350px;
        }
        @media (max-width: $sm) {
          font-size: 10pt;
          width: 300px;
        }
      }
    }

    .header-button {
      margin-top: 40px;
      @media (max-width: $md) {
        margin-top: 20px;
      }
      @media (max-width: $tb) {
        margin-top: 5px;
      }
      .button {
        font-family: $digital;
        font-size: 14pt;
        border: $secondary 1px solid;
        border-radius: 5px;
        padding: 15px 50px;
        color: $secondary;
        transition: 1;
        background-color: transparent;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          transition: 1;
          background-color: rgba($secondary, 0.3);
        }
        @media (max-width: $md) {
          font-size: 12pt;
          padding: 10px 40px;
        }
      }
    }
  }
}
