@import "./variables.scss";

.footer-container {
  width: 100%;
  height: 75px;
  background-color: $primary;
  .footer-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      text-decoration: none;
      text-align: center;
      color: $text-secondary;
      font-family: $digital;
      .name-tag {
        color: $text-primary;
        text-decoration: none;
        &:hover {
          cursor: pointer;
          color: $secondary;
        }
      }
    }
  }
}
